<template>
   
  
    <div v-if="dataObject.state.isLoading" class="position-absolute position-absolute top-0 end-0 h-100 w-100 d-flex overlay ">
        <div class="spinner-border m-auto" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
    <div class=" list-group rounded-0 border-top border-bottom" id="filesList" >
        
            <div class="overflow " ref = "elRef" @scroll="handleScroll" @dragstart="dragStart($event)">
                <template v-for="(item,index) in dataObject.data" >
                    <slot :item="item"></slot>
                </template>
            </div>
    </div>
    <div v-if="dataObject.state.isNextPageLoading" class="progress w-100" style="flex:0 0 8px;">
        <div class="progress-bar indeterminate-progress" role="progressbar"></div>
    </div>
    <div class="d-flex" style="flex:0 0 40px;">
        <InfoItems :dataObject = "dataObject"/>
    </div>
  
</template>
<script setup>
    import {ref} from 'vue';
    import OFilterString from 'o365.vue.components.FilterString.vue';
    import useVirtualScroll from 'o365.vue.composables.VirtualScroll.js';
    import InfoItems from 'o365.vue.components.InfoItems.vue';
    const elRef = ref(null);
    const props = defineProps({
        dataObject:{
            required:true
        },
      
    });

    const listClick = (item)=>{
        props.dataObject.setCurrentIndex(item.index,true);
    }


    const {handleScroll,scrollData} = useVirtualScroll({
        dataRef:props.dataObject.data,
        itemSize:33,
        itemsToRender:100,
        recycleList:true,
        elementRef:elRef,
        dynamicLoading:props.dataObject.dynamicLoading
    });

    const dragStart = (e)=>{
        if(e.target.title){
            e.dataTransfer.setData("text", e.target.title);
        }else{
            e.dataTransfer.setData("text", e.target.innerText);
        }
      
    }

</script>
<style scoped>
    .overflow{
        overflow-y: auto;
    }
    .overlay{
        z-index: 999;
        background: #0000002b;
    }
</style>